import type { PageProps } from '~/@types/generic';
import type { Meta, Link } from '@unhead/vue';

export const useSeo = (props: PageProps) => {
    const appStore = useAppStore();
    const config = useRuntimeConfig();
    const route = useRoute();
    const { locale } = useLocales();

    const baseUrl = config.public.BASE_URL;

    const defaultSeo = computed(() => appStore.seo);
    const title = computed(() => props.pageData?.seo?.title || defaultSeo.value?.title);
    const pageDescription = computed(() => props.pageData?.seo?.description || defaultSeo.value?.description);
    const description = computed(() => pageDescription || defaultSeo.value?.description);

    const link = computed<Link[]>(() => {
        const links: Link[] = [
            { rel: 'apple-touch-icon', sizes: '57x57', href: '/apple-touch-icon-57x57.png' },
            { rel: 'apple-touch-icon', sizes: '60x60', href: '/apple-touch-icon-60x60.png' },
            { rel: 'apple-touch-icon', sizes: '72x72', href: '/apple-touch-icon-72x72.png' },
            { rel: 'apple-touch-icon', sizes: '76x76', href: '/apple-touch-icon-76x76.png' },
            { rel: 'apple-touch-icon', sizes: '114x114', href: '/apple-touch-icon-114x114.png' },
            { rel: 'apple-touch-icon', sizes: '120x120', href: '/apple-touch-icon-120x120.png' },
            { rel: 'apple-touch-icon', sizes: '144x144', href: '/apple-touch-icon-144x144.png' },
            { rel: 'apple-touch-icon', sizes: '152x152', href: '/apple-touch-icon-152x152.png' },
            { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon-180x180.png' },
            { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
            { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
            { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
            { rel: 'manifest', href: '/site.webmanifest' },
            { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#eb0613' },
        ];
        if (!Array.isArray(props.pageData?.cannonical) || !props.pageData?.url) return links;

        const pageUrlPath = props.pageData.url.url === '/' ? '' : props.pageData.url.url;
        links.push({
            rel: 'canonical',
            href: `${baseUrl}/${props.pageData.url.locale}${pageUrlPath}`,
        });

        const addAlternate = ({ href, hreflang }: { href: string; hreflang: string }) => {
            links.push({
                rel: 'alternate',
                hreflang,
                href,
            });
        };

        for (const cannonical of props.pageData.cannonical) {
            const canonicalLocale = cannonical.locale;
            if (!woomConfig.locales.find(({ code }) => code === canonicalLocale)) continue;

            const urlPath = cannonical.url === '/' ? '' : cannonical.url;
            const href = `${baseUrl}/${canonicalLocale}${urlPath}`;
            const [lang, region] = canonicalLocale.split('_');
            if (region?.toUpperCase() === 'INT') {
                addAlternate({
                    hreflang: lang,
                    href,
                });
                // https://developers.google.com/search/docs/specialty/international/localized-versions#html
                // set the x-default pointing to the INT page
                addAlternate({
                    hreflang: 'x-default',
                    href,
                });
                continue;
            }

            addAlternate({
                hreflang: canonicalLocale.replace('_', '-'),
                href,
            });
            if (canonicalLocale.toLowerCase() === 'en_us') {
                addAlternate({
                    hreflang: 'en-CA',
                    href,
                });
            }
        }
        return links;
    });

    const socialImage = computed(() => {
        const imageURL = props.pageData?.page?.product?.shopImage;
        const imageData = imageURL ? defaultSeo.value?.image : props.pageData?.seo?.image || defaultSeo.value?.image;

        if (!imageData?.id) return imageURL;
        if (imageData.id.startsWith('a:')) return `${imageData.data.src}?w=800`;
        if (imageData.id.startsWith('b:')) return `${imageData.data.baseUrl}${imageData.data.image}`;
    });

    const meta = computed<Meta[]>(() => {
        const meta: Meta[] = [
            {
                hid: 'woom-base-url',
                property: 'woom:base',
                content: baseUrl,
            },
            {
                hid: 'og_url',
                property: 'og:url',
                content: `${baseUrl}${route.fullPath}`,
            },
            {
                hid: 'og_locale',
                property: 'og:locale',
                content: locale.value,
            },
            {
                hid: 'msapplication-TileColor',
                property: 'msapplication-TileColor',
                content: '#ffffff',
            },
            {
                hid: 'theme-color',
                property: 'theme-color',
                content: '#ffffff',
            },
        ];

        if (title.value) {
            meta.push({ hid: 'og_title', property: 'og:title', content: title.value });
            meta.push({ hid: 'twitter_title', name: 'twitter:title', content: title.value });
        }
        if (description.value) {
            meta.push({ hid: 'description', name: 'description', content: description.value });
            meta.push({
                hid: 'og_description',
                property: 'og:description',
                content: description.value,
            });
            meta.push({
                hid: 'twitter_description',
                name: 'twitter:description',
                content: description.value,
            });
        }
        if (socialImage.value) {
            meta.push({ hid: 'og_image', property: 'og:image', content: socialImage.value });
            meta.push({
                hid: 'twitter_image',
                name: 'twitter:image',
                content: socialImage.value,
            });
        }
        return meta;
    });

    useHead({
        title: title.value,
        htmlAttrs: {
            lang: locale.value.replace('_', '-'),
        },
        meta,
        link,
    });
};
