export const woomConfig = {
    locales: [
        { code: 'de_AT', countryISO2: 'at', iso: 'de-at', files: ['de.ts', 'de_AT.ts'], name: 'Österreich' },
        { code: 'de_DE', countryISO2: 'de', iso: 'de-de', files: ['de.ts', 'de_DE.ts'], name: 'Deutschland' },
        { code: 'de_CH', countryISO2: 'ch', iso: 'de-ch', files: ['de.ts', 'de_CH.ts'], name: 'Schweiz' },
        { code: 'fr_CH', countryISO2: 'ch', iso: 'fr-ch', files: ['fr.ts', 'fr_CH.ts'], name: 'Suisse' },
        { code: 'en_GB', countryISO2: 'gb', iso: 'en-gb', files: ['en.ts', 'en_GB.ts'], name: 'United Kingdom' },
        { code: 'en_US', countryISO2: 'us', iso: 'en-us', files: ['en.ts', 'en_US.ts'], name: 'United States' },
        { code: 'fr_FR', countryISO2: 'fr', iso: 'fr-fr', files: ['fr.ts'], name: 'France' },
        { code: 'sv_SE', countryISO2: 'se', iso: 'sv', files: ['sv.ts'], name: 'Sweden' },
        { code: 'da_DK', countryISO2: 'dk', iso: 'da', files: ['da.ts'], name: 'Denmark' },
        { code: 'nl_NL', countryISO2: 'nl', iso: 'nl', files: ['nl.ts'], name: 'Netherlands' },
        { code: 'en_INT', countryISO2: 'ie', iso: 'en', files: ['en.ts', 'en_INT.ts'], name: 'International' },
        // Externals:
        // Poland
        // Czech
        // Romania
        // Hungary
        // Slovakia
    ],
};
